body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-pagination {
  bottom: 20px;
}

.my-bullet {
  background-color: #ccc;
  opacity: 1;
}

.my-bullet-active {
  background-color: #1E2756;
  opacity: 1;
}

.ribbon {
  /* Center the content */
  align-items: center;
  display: flex;
  justify-content: center;

  /* Size */
  height: 2rem;

  /* Use to position the corners */
  position: relative;
}

.ribbon__content {
  /* Background color */
  background-color: #1E2756;
  z-index: 1;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}
.ribbon__side {
  bottom: -0.5rem;
  position: absolute;
  /* Displayed under the ribbon */
  z-index: 1;
  /* Background */
  border: 1rem solid #d1d5db;
}

.ribbon__side--l {
  /* Position */
  left: -1.5rem;
  border-color: #D45F46 #D45F46 #D45F46 transparent;
}

.ribbon__side--r {
  /* Position */
  right: -1.5rem;
  border-color: #D45F46 transparent #D45F46 #D45F46;
}

.ribbon__triangle {
  position: absolute;
  top: 100%;

  border: 0.5rem solid transparent;
  border-bottom-width: 0;
  border-top-color: #aaa;
}

.ribbon__triangle--l {
  border-right-width: 0;
  left: 0;
}

.ribbon__triangle--r {
  border-left-width: 0;
  right: 0;
}

