#app { height: 100% }

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper {
  width: 320px;
  height: 400px;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}
